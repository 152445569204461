.advert_top {
    margin-top: 273px;
}

.top-mobile-advert-row {
}

.top-mobile-advert-div {
    text-align: center;
    margin: 10px auto;
}

.title {
    font-size: 32px;
    margin-top: 5px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .title {
        font-size: 28px;
    }
}

@media (max-width: 1199px) {
    .advert_top {
        margin-top: 359px;
    }
}

.page-reference {
    padding: 20px;
    border: 1px solid black;
}

.caption {
    margin-top: 8px;
    font-weight: bold;
    font-size: 18px;
}

.subcaption {
    margin-top: 15px;
    color: #282b36;
    font-size: 15.6px;
}

@media (max-width: 767px) {
    .title {
        font-size: 28px;
        margin-top: 5px;
        margin-bottom: 9px;
    }

    .caption {
        font-size: 16px;
    }

    .subcaption {
        margin-top: 15px;
        font-size: 14px;
    }
}

.page-references {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 20px;
}

a.page-reference:focus,
a.page-reference:hover {
    text-decoration: none;
}

a.page-reference:focus .page-reference_title,
a.page-reference:hover .page-reference_title {
    text-decoration: underline;
}

.page-reference {
    width: 30%;
    margin-top: 10px;
    margin-right: 3%;
    margin-bottom: 10px;
    min-height: 150px;
}

@media (max-width: 768px) {
    .page-reference {
        width: 44%;
    }
}

@media (max-width: 576px) {
    .page-reference {
        margin: 10px 0;
        width: 100%;
    }

}

.page-reference_text {
    margin-top: 30px;
    color: #666c7d;
    font-size: 14px;
}

.cat-logo {
    display: none;
    position: relative;
    max-width: 120%;
    top: -10px;
    left: 50px;
    margin: -70px;
    z-index: 10;
}

.cat-logo-mini {
    position: relative;
    z-index: 2;
    top: -5px;
    float: right;
    margin-bottom: -47px;
    display: none;
}


.cat-logo-mini {
    display: inline
}


@media (min-width: 576px) {
    .cat-logo-mini{
        display: none;
    }
    .cat-logo{
        display: inline;
        top: 70px;
        left: 50px;
    }
}

@media (min-width: 768px) {
    .cat-logo{
        top: 33px;
        left: 29px;
    }
}

@media (min-width: 992px) {
    .cat-logo{
        top: 68px;
        left: 39px;
    }
}

@media (min-width: 1200px) {
    .cat-logo{
        top: 10px;
        left: 39px;
    }
}

.too_many_letters {
    margin-top: 30px;
    color: #282b36;
    font-size: 14px;
}

.home_comments {
    color: #282b36;
    font-size: 14px;
}

@media (max-width: 576px) {
    .text_description {
        width: 100%;
    }
}

.lyrical_digression_h {
    text-align: center;
    font-size: 18px;
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
    font-style: italic;
}

@media (min-width: 992px) {
    .col-offset-336 {
        padding-right: 300px;
        z-index: 0;
    }

    .col-fixed-336 {
        width: 300px;
        /*position: absolute;*/
        margin-left: -300px;
        z-index: 1;
    }
}

@media (min-width: 1200px) {

    .col-offset-336 {
        padding-right: 336px;
        z-index: 0;
    }

    .col-fixed-336 {
        width: 336px;
        /*position: absolute;*/
        margin-left: -336px;
        z-index: 1;
    }
}

@media (max-width: 992px) {
    .col-fixed-336 {
        padding-right: inherit;
    }
}
